import { HolisticProjectTypes } from '@aims/shared/holistic-projects/constants';
import { Form, Select } from 'antd';
import React from 'react';
import SelectRegion from '../../shared/SelectRegion';

function SelectProjectType({ value, onChange }) {
  return (
    <Select
      placeholder="Select a Project Type"
      allowClear
      value={value}
      onChange={onChange}
    >
      {Object.values(HolisticProjectTypes).map((p) => (
        <Select.Option key={p.key} value={p.key}>
          {p.label}
        </Select.Option>
      ))}
    </Select>
  );
}

function HolisticProjectFilters({ form, onFiltersChanged }) {
  return (
    <div
      style={{
        maxWidth: 800,
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <Form layout="vertical" form={form} onValuesChange={onFiltersChanged}>
        <Form.Item name="regionCode" label="Select a Region">
          <SelectRegion filters={{}} />
        </Form.Item>
        <Form.Item name="typeOfProject" label="Select a Project Type">
          <SelectProjectType />
        </Form.Item>
      </Form>
    </div>
  );
}

export default HolisticProjectFilters;
